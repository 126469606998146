import useJwt from '@/auth/jwt/useJwt'

export default class SusiService {
  static get(userId, field) {
    return useJwt.get(`/susi/${field}/${userId}`)
  }

  static getClaudeCategory(product) {
    return useJwt.get('/susi/claude/product-to-category', { name: product }, {
      product,
    })
  }

  static getGptCategory(product) {
    return useJwt.get('/susi/gpt/product-to-category', { name: product }, {
      product,
    })
  }

  static getGptCategoryV2(product) {
    return useJwt.get('/susi/gpt/product-to-category-v2', { name: product }, {
      product,
    })
  }

  static getGptCategoryV3(product) {
    return useJwt.get('/susi/gpt/product-to-category-v3', { name: product }, {
      product,
    })
  }

  static byPrompt(prompt, replaces) {
    return useJwt.post('/susi/by-prompt', {}, {
      prompt,
      replaces,
    })
  }
}
